<template>
  <div class="hb_index">
    <Carousel
      :list="basicData.lbList"
      class="hb_Carousel"
      v-if="Object.keys(basicData).length != 0"
    >
    </Carousel>
    <zCarousel
      :list="basicData.lbList2"
      class="sj_Carousel"
      v-if="Object.keys(basicData).length != 0"
    ></zCarousel>
    <div class="hb_work hb_nav">
      <div class="hb_work_nav hb_nav_col">
        <div class="nav_col">
          <h2>
            <b style="color:#004fab;">{{ workData.workB }}</b
            >{{ workData.workTitle }}
          </h2>
          <p>{{ workData.workText }}</p>
        </div>
        <div class="nav_img">
          <img :src="workData.workImg" alt="" style="width:100%" />
        </div>
      </div>
      <ul class="hb_work_nav hb_nav_col nav2">
        <li v-for="(item, idx) in workData.workList" :key="idx">
          <i class="centerText">{{ item.key }}</i>
          <span>{{ item.value }}</span>
        </li>
      </ul>
    </div>
    <div class="hb_standard hb_nav" v-if="Object.keys(basicData).length != 0">
      <div class="all_title">
        <h2>{{ basicData.basicTit[0].key }}</h2>
        <p>
          <span>{{ basicData.basicTit[0].value }}</span>
        </p>
      </div>
      <div class="hb_nav_col">
        <vue-seamless-scroll
          :data="zlData.zlList"
          :class-option="optionLeft"
          class="seamless-warp"
        >
          <ul class="warp_item">
            <li
              v-for="(item, index) in zlData.zlList"
              :key="index"
              :data-index="index"
            >
              <div class="li_img">
                <img :src="zlData.zlImg[index]" alt="" />
              </div>
              <div class="li_nav">
                <div class="icon">
                  <i :style="{ backgroundImage: `url(${item.value})` }"></i>
                </div>
                <div class="text">{{ item.key }}</div>
                <div class="num">0{{ index + 1 }}</div>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
    <div
      class="hb_service hb_nav"
      v-if="Object.keys(basicData).length != 0"
      :style="{
        background: `url(${basicData.basicBg[0]}) no-repeat center`
      }"
    >
      <div class="all_title">
        <h2>{{ basicData.basicTit[1].key }}</h2>
        <p>
          <span>{{ basicData.basicTit[1].value }}</span>
        </p>
      </div>
      <ul class="hb_service_nav hb_nav_col">
        <li v-for="(item, idx) in fwList" :key="idx">
          <a
            :href="'#/hb_service?currentId=' + item.id"
            @click="toLink(102)"
          >
            <div class="icon">
              {{ item.title.slice(0, 2) }}<br />
              <b style="margin-top:10px;display:block">{{
                item.title.slice(2)
              }}</b>
            </div>
            <div class="image zz_all_img">
              <div class="image_i">
                <img :src="item.site_images" alt="" style="width:100%" />
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="hb_art hb_nav"
      v-if="Object.keys(basicData).length != 0"
      :style="{
        background: `url(${basicData.basicBg[1]}) no-repeat center bottom`
      }"
    >
      <div class="all_title">
        <h2>{{ basicData.basicTit[2].key }}</h2>
        <p>
          <span>{{ basicData.basicTit[2].value }}</span>
        </p>
      </div>
      <div class="hb_nav_col">
        <ul class="nav_col">
          <li
            v-for="(item, idx) in ysData.ysList"
            :key="idx"
            :style="{
              background: `url(${ysData.ysList2[idx].value}) no-repeat 0 5px`
            }"
          >
            <h2>{{ item.key }}</h2>
            <h4>{{ ysData.ysList2[idx].key }}</h4>
            <p>{{ item.value }}</p>
          </li>
        </ul>
        <div class="nav_tel">
          <h2>
            {{ ysData.ysPhone[0].key }}
            <span> {{ ysData.ysPhone[0].value }}</span>
          </h2>
          <p>{{ ysData.ysPhone[1].key }}<br />{{ ysData.ysPhone[1].value }}</p>
        </div>
      </div>
    </div>
    <div
      class="hb_peace hb_nav"
      v-if="Object.keys(basicData).length != 0"
      :style="{
        background: `url(${basicData.basicBg[2]}) no-repeat center bottom`
      }"
    >
      <div class="all_title">
        <h2>{{ basicData.basicTit[3].key }}</h2>
        <p>
          <span>{{ basicData.basicTit[3].value }}</span>
        </p>
      </div>
      <div class="hb_nav_col">
        <ul class="peace_tit">
          <li
            v-for="(item, idx) in yzsList"
            :key="idx"
            :class="[active == idx ? 'xz_li' : '']"
            @mouseover="active = idx"
          >
            <i
              :style="{
                background: `url(${item.icon_image}) no-repeat 0 center`
              }"
            ></i>
            {{ item.name }}
          </li>
        </ul>
        <ul class="peace_nav">
          <li
            v-for="(item, idx) in axList"
            :key="idx"
            :style="{ display: active == idx ? 'block' : 'none' }"
          >
            <a
              :href="
                '#/hb_anli?currentIdx=' + (active + 1) + '&currentId=' + item.id
              "
              @click="toLink(103)"
            >
              <div class="li_img">
                <img :src="item.site_images" alt="" />
              </div>
              <div class="li_text">
                <h2>{{ item.title }}</h2>
                <h4>
                  <p>{{ item.subtitle }}</p>
                  <span>查看更多</span>
                </h4>
              </div>
            </a>
          </li>
        </ul>
        <div class="peace_img">
          <img :src="basicData.basicBg[3]" alt="" style="width:100%" />
        </div>
      </div>
    </div>
    <div
      class="hb_step hb_nav"
      v-if="Object.keys(basicData).length != 0"
      :style="{
        background: `url(${basicData.basicBg[4]}) no-repeat center bottom`
      }"
    >
      <div class="hb_nav_col">
        <div class="col_left">
          <div class="col_left_tit">
            <b>{{ lcData.lc_tit }}</b>
            {{ lcData.lc_text }}
          </div>
          <div class="col_left_img">
            <img :src="lcData.lc_img" alt="" />
          </div>
        </div>
        <ul class="col_right">
          <li v-for="(item, idx) in lcData.lc_list" :key="idx">
            <h2>
              <i>
                <img :src="lcData.lc_list2[idx]" alt="" />
              </i>
              <span>{{ item.key }}</span>
            </h2>
            <p>{{ item.value }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="hb_news hb_nav" v-if="Object.keys(basicData).length != 0">
      <div class="all_title">
        <h2>{{ basicData.basicTit[4].key }}</h2>
        <p>
          <span>{{ basicData.basicTit[4].value }}</span>
        </p>
      </div>
      <div class="hb_nav_col">
        <ul class="hb_news_type">
          <li
            v-for="(item, idx) in newsList"
            :key="idx"
            :class="[newsActive == idx ? 'xz_news' : '']"
            @mouseover="newsActive = idx"
          >
            {{ item.name }}
          </li>
        </ul>
        <div
          class="hb_news_nav"
          v-for="(item, idx) in newsList"
          :key="idx"
          :style="{ display: newsActive == idx ? 'flex' : 'none' }"
        >
          <div class="nav_col">
            <a
              class="nav_col_img"
              :href="
                '#/hb_news?currentIdx=' +
                  (newsActive + 1) +
                  '&currentId=' +
                  item.tuijian.id
              "
              @click="toLink(104)"
            >
              <img :src="item.icon_image" alt="" />
            </a>
            <a
              class="nav_col_text"
              :href="
                '#/hb_news?currentIdx=' +
                  (newsActive + 1) +
                  '&currentId=' +
                  item.tuijian.id
              "
              @click="toLink(104)"
            >
              <div class="date">
                <span>{{ item.tuijian.create_time.slice(5) }}</span>
                {{ item.tuijian.create_time.slice(0, 4) }}
              </div>
              <div class="text">
                <h2 class="ellipsis">{{ item.tuijian.title }}</h2>
                <p class="two_ellipsis">{{ item.tuijian.miaoshu }}</p>
              </div>
            </a>
          </div>
          <ul class="nav_col col2">
            <a
              v-for="(el, i) in item.news"
              :key="i"
              class="nav_col_text"
              :href="
                '#/hb_news?currentIdx=' +
                  (newsActive + 1) +
                  '&currentId=' +
                  el.id
              "
              @click="toLink(104)"
            >
              <div class="date">
                <span>{{ item.tuijian.create_time.slice(5) }}</span>
                {{ item.tuijian.create_time.slice(0, 4) }}
              </div>
              <div class="text">
                <h2 class="ellipsis">{{ item.tuijian.title }}</h2>
                <p class="two_ellipsis">{{ item.tuijian.miaoshu }}</p>
              </div>
            </a>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="hb_about hb_nav"
      v-if="Object.keys(basicData).length != 0"
      :style="{
        background: `url(${basicData.basicBg[5]}) no-repeat center`
      }"
    >
      <div class="all_title">
        <h2>{{ basicData.basicTit[5].key }}</h2>
        <p>
          <span>{{ basicData.basicTit[5].value }}</span>
        </p>
      </div>
      <div class="hb_nav_col">
        <div class="col_text">{{ aboutData.gy_text }}</div>
        <ul class="col_list">
          <li v-for="(item, idx) in aboutData.gy_list" :key="idx">
            <a href="#/hb_about" @click="toLink(105)">
              <img :src="item.value" alt="" />
              <span>{{ item.key }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBasicMsg,
  getPageMsg,
  getXwData,
  getProductList,
  getCaseTypes,
  getCaseList
} from '@/api/api'
import vueSeamlessScroll from 'vue-seamless-scroll'
import Carousel from '@/common/Carousel/zzCarousel.vue'
import zCarousel from '@/common/Carousel/zzCarousel1.vue'
export default {
  components: {
    Carousel,
    zCarousel,
    vueSeamlessScroll,
  },
  computed: {
    optionLeft () {
      return {
        step: 0.5, // 数值越大速度滚动越快
        direction: 2, // 0向下 1向上 2向左 3向右
        limitMoveNum: this.zlData.zlList.length, // 开始无缝滚动的数据量
        hoverStop: true, // 是否开启鼠标悬停
        openTouch: false,
        openWatch: true // 开启数据实时监控刷新
      }
    }
  },
  data () {
    return {
      basicData: {},
      workData: {},
      fwList: [],
      artData: {},
      zlData: {},
      ysData: {},
      yzsList: [],
      active: 0,
      axList: [],
      lcData: {},
      newsList: [],
      newsActive: 0,
      aboutData: {}
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      getBasicMsg(this.changeData()).then(item => {
        if (item.data.code == 1) {
          let id = item.data.data.menu_id
          let project = item.data.data.project_id
          getPageMsg(id).then(res => {
            if (res.data.code == 1) {
              that.basicData = res.data.data.basic
              that.workData = res.data.data.work
              that.zlData = res.data.data.治理
              that.ysData = res.data.data.艺术
              that.lcData = res.data.data.流程
              that.aboutData = res.data.data.关于
            }
          })
          getCaseTypes(project).then(res => {
            if (res.data.code == 1) {
              that.yzsList = res.data.data
            }
          })
          getCaseList(1, '', project).then(res => {
            if (res.data.code == 1) {
              let arr = []
              res.data.data.list.forEach((el, i) => {
                if (i < 5) {
                  arr.push(el)
                }
              })
              that.axList = arr
            }
          })
          getProductList(1, '', project).then(res => {
            if (res.data.code == 1) {
              let arr = []
              res.data.data.list.forEach((el, i) => {
                if (i < 4) {
                  arr.push(el)
                }
              })
              that.fwList = arr
            }
          })
          getXwData(project).then(res => {
            if (res.data.code == 1) {
              that.newsList = res.data.data
            }
          })
        }
      })
    },
    toLink (i) {
      sessionStorage.setItem(this.changeData() + 'id', i)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.hb_index {
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  color: #212529;
  /deep/.hb_Carousel {
    overflow: hidden;
    img {
      line-height: 0;
      max-width: 100%;
      position: relative;
      animation: scaleAnim 3s ease forwards;
    }
  }
  .sj_Carousel {
    display: none;
  }
  .hb_nav {
    padding: 80px 0;
    .all_title {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 60px;
      h2 {
        font-size: 40px;
        font-weight: 600;
      }
      p {
        position: relative;
        margin-top: 10px;
        span {
          font-size: 14px;
          display: inline-block;
          vertical-align: middle;
          padding: 8px 15px;
          border: 1px solid #ddd;
          background-color: #fff;
          position: relative;
        }
      }
      p::before {
        position: absolute;
        left: 0;
        top: 50%;
        height: 1px;
        width: 100%;
        background-color: #ddd;
        content: '';
      }
    }
    .hb_nav_col {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
    }
  }
  .hb_work {
    background-color: #f3f3f3;
    .hb_work_nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .nav_col {
        width: 460px;
        line-height: 1.5;
        h2 {
          margin: 0;
          font-weight: 600;
          font-size: 40px;
        }
        h2::before {
          display: block;
          content: '';
          height: 2px;
          width: 40px;
          background-color: #004fab;
          margin-bottom: 30px;
        }
        p {
          color: #666;
          line-height: 2;
          font-size: 14px;
          text-align: justify;
          margin: 30px 0 0;
        }
      }
      .nav_img {
        width: 600px;
        overflow: hidden;
      }
      li {
        width: 16.666666%;
        text-align: center;
        cursor: pointer;
        i {
          width: 100px;
          height: 100px;
          margin: 0 auto;
          font-size: 40px;
          border: 1px solid #ccc;
          border-radius: 50%;
          font-weight: 600;
          transition: all linear 0.3s;
        }
        span {
          display: block;
          font-size: 18px;
          margin-top: 20px;
        }
      }
      li:hover i {
        background-color: #004fab;
        color: #fff;
      }
    }
    .nav2 {
      margin-top: 80px;
    }
  }
  .hb_standard .hb_nav_col .seamless-warp {
    width: 100%;
    .warp_item {
      width: 100%;
      display: flex;
      li {
        width: 220px;
        margin-right: 20px;
        .li_nav {
          position: relative;
          z-index: 1;
          .icon {
            border: 8px solid #fff;
            border-radius: 50%;
            width: 84px;
            height: 84px;
            margin: -50px auto 0;
            background-color: #e3e3e3;
            position: relative;
            i {
              position: absolute;
              width: 58px;
              height: 54px;
              left: 50%;
              top: 50%;
              margin: -27px 0 0 -29px;
              background: no-repeat center 0;
              transition: all 0.5s;
            }
          }
          .text {
            text-align: center;
            margin: 15px 0 0;
            height: 80px;
            font-weight: normal;
            font-size: 20px;
            color: #666;
          }
          .num {
            display: block;
            text-align: center;
            font-family: Impact;
            font-size: 18px;
            color: #666;
            background-color: #fff;
            width: 40px;
            margin: 0 auto;
          }
        }
        .li_nav:hover {
          .icon {
            background-color: #004fab;
            i {
              background-position: -6px -55px !important;
            }
          }
          .text {
            color: #004fab;
          }
        }
      }
    }
    .warp_item::after {
      position: absolute;
      content: '';
      bottom: 8px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #d8d8d8;
    }
  }
  .hb_service .hb_service_nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: 18%;
      .icon {
        text-align: center;
        padding-top: 75px;
        height: 140px;
        font-size: 26px;
        position: relative;
        z-index: 1;
        transition: all 0.5s;
        background: url(~@/assets/image/all/hb_wxz.png) no-repeat center;
        background-size: contain !important;
      }
      .icon:hover {
        background: url(~@/assets/image/all/hb_xz.png) no-repeat center;
        color: #fff;
      }
      .image {
        background-color: #004fab;
        color: #fff;
        padding: 200px 20px 20px;
        margin-top: -110px;
        .image_i {
          width: 100%;
          overflow: hidden;
        }
      }
    }
  }
  .hb_art .hb_nav_col {
    position: relative;
    .nav_col {
      li {
        margin-bottom: 40px;
        padding-left: 70px;
        h2 {
          margin-left: 20px;
          font-size: 30px;
          font-weight: 600;
        }
        h4 {
          font-weight: normal;
          background: url(~@/assets/image/all/hb_ysbj.png) no-repeat 0 0;
          line-height: 30px;
          color: #fff;
          margin: 5px 0 10px 0;
          padding-top: 7px;
          font-size: 18px;
          padding-left: 20px;
        }
        p {
          font-size: 14px;
          color: #666;
          padding-left: 20px;
        }
      }
    }
    .nav_tel {
      position: absolute;
      right: 260px;
      bottom: 30px;
      text-align: right;
      z-index: 10;
      h2 {
        color: #01923f;
        font-size: 26px;
        font-weight: 600;
        span {
          display: block;
          color: #fff;
          line-height: 65px;
        }
      }
      p {
        font-size: 26px;
        font-family: Impact;
        margin: 20px 65px 0 0;
      }
    }
  }
  .hb_peace {
    .all_title h2 {
      color: #fff;
    }
    .hb_nav_col {
      width: 1100px;
      padding: 50px;
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .peace_tit {
        width: 150px;
        background-color: #eceeef;
        li {
          padding: 30px 0;
          cursor: pointer;
          text-align: center;
          border-top: 2px solid #dadada;
          font-size: 14px;
          i {
            display: block;
            height: 39px;
            width: 44px;
            margin: 0 auto 10px;
          }
        }
        li:nth-child(1) {
          border-top: 0;
        }
        .xz_li {
          background-color: #004fab;
          color: #fff;
          i {
            background-position: right center !important;
          }
        }
      }
      .peace_nav {
        width: calc(100% - 230px);
        margin-left: 30px;
        background: url(~@/assets/image/gif/case_en.gif) no-repeat right bottom;
        padding-right: 50px;
        position: relative;
        li {
          width: 100%;
          animation: switchShow 0.5s ease forwards;
          .li_img {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .li_text {
            margin-top: 25px;
            background: url(~@/assets/image/gif/yhico.gif) no-repeat right 0;
            h2 {
              font-size: 24px;
            }
            h4 {
              display: flex;
              justify-content: space-between;
              margin: 15px 0 0;
              p {
                font-size: 14px;
                color: #666;
                overflow: hidden;
                line-height: 1.8;
                width: 80%;
              }
              span {
                background: url(~@/assets/image/gif/case_btn.gif) no-repeat 0 0;
                width: 150px;
                line-height: 43px;
                color: #fff;
                text-indent: 20px;
                margin-top: 8px;
              }
            }
          }
        }
      }
      .peace_img {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  .hb_step .hb_nav_col {
    width: 1100px;
    background-color: #fff;
    padding: 40px 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .col_left {
      width: 375px;
      .col_left_tit {
        font-size: 18px;
        margin-bottom: 30px;
        b {
          display: block;
          font-size: 40px;
          line-height: 1.5;
        }
      }
      .col_left_tit::before {
        display: block;
        content: '';
        height: 2px;
        width: 40px;
        background-color: #004fab;
        margin-bottom: 30px;
      }
      .col_left_img {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .col_right {
      width: calc(100% - 400px);
      display: flex;
      flex-wrap: wrap;
      li {
        width: 310px;
        margin-left: 40px;
        margin-top: 20px;
        h2 {
          i {
            display: inline-block;
            vertical-align: middle;
            width: 63px;
            line-height: 63px;
            text-align: center;
            background-color: #004fab;
            border-radius: 50%;
            margin-right: 15px;
          }
          span {
            display: inline-block;
            vertical-align: middle;
            font-size: 24px;
          }
        }
        p {
          color: #666;
          text-align: justify;
          margin: 10px 0 0;
          font-size: 14px;
          line-height: 1.5;
        }
      }
      li:hover h2 i {
        background-color: #01923f;
      }
    }
  }
  .hb_news .hb_nav_col {
    .hb_news_type {
      display: flex;
      margin-bottom: 30px;
      li {
        margin-right: 15px;
        width: 140px;
        text-align: center;
        line-height: 50px;
        background-color: #f1f1f1;
        font-size: 16px;
        cursor: pointer;
      }
      .xz_news {
        background-color: #004fab;
        color: #fff;
      }
    }
    .hb_news_nav {
      animation: switchShow 0.5s ease forwards;
      justify-content: space-between;
      .nav_col {
        width: 45%;
        .nav_col_img {
          width: 100%;
          height: 280px;
          display: block;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .nav_col_text {
          margin: 40px 0 0;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          .date {
            text-align: right;
            padding-right: 15px;
            width: 90px;
            font-size: 16px;
            position: relative;
            span {
              display: block;
              color: #01923f;
              font-size: 30px;
              font-family: Impact;
              line-height: 1;
              margin-bottom: 5px;
            }
          }
          .date::before {
            position: absolute;
            right: 0;
            top: 5px;
            height: 20px;
            width: 2px;
            margin-right: -1.5px;
            background-color: #01923f;
            content: '';
          }
          .text {
            width: calc(100% - 120px);
            padding-left: 15px;
            padding-top: 5px;
            h2 {
              margin: 0;
              font-weight: normal;
              line-height: 1;
              color: #01923f;
            }
            p {
              font-size: 14px;
              color: #999;
              overflow: hidden;
              margin: 10px 0 0;
              line-height: 1.5;
            }
          }
        }
      }
      .col2 .nav_col_text {
        margin: 0;
        .date {
          padding-top: 36px;
          span {
            color: #333;
          }
        }
        .date::before {
          background-color: #a3a3a3;
          top: 39px;
        }
        .text {
          padding-top: 40px;
          padding-bottom: 34px;
          border-left: 1px solid #efefef;
          border-top: 1px solid #efefef;
          box-sizing: border-box;
          h2 {
            color: #333;
          }
        }
      }
      .nav_col_text:nth-child(1) {
        .date {
          padding-top: 0;
        }
        .date::before {
          top: 5px;
        }
        .text {
          border-top: none;
          padding-top: 5px;
        }
      }
      .col2 .nav_col_text:hover {
        .date {
          color: #004fab;
          span {
            color: #004fab;
          }
        }
        .text h2 {
          color: #004fab;
        }
      }
    }
  }
  .hb_about .hb_nav_col {
    text-align: center;
    .col_text {
      width: 80%;
      margin: 0 auto;
      color: #333;
      font-size: 14px;
      line-height: 1.8;
    }
    .col_list {
      display: flex;
      justify-content: center;
      padding-bottom: 100px;
      li {
        vertical-align: middle;
        margin: 0 30px;
        margin-top: 50px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #004fab;
        color: #fff;
        cursor: pointer;
        a {
          padding-top: 10px;
          display: block;
        }
        img {
          display: inline-block;
          vertical-align: middle;
        }
        span {
          display: block;
          font-size: 16px;
          margin-top: 10px;
        }
      }
      li:hover {
        background-color: #01923f;
      }
    }
  }
}
@keyframes switchShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaleAnim {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>
